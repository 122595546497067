.head {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;

  .block {
    opacity: 0;
  }
  .headRight {
    display: flex;
    align-items: center;
    height: 40px;
    & > div > div:not(:first-child):not(:last-child) {
      display: none;
    }
    & > div div > div:not(:first-child):not(:last-child) {
      display: none;
    }
    & > div:nth-child(4) div > span {
      display: none;
    }
    & > div:nth-child(4) > span {
      display: none;
    }
    .antdDrop {
      display: flex;
    }
    .dots {
      display: flex;
      margin-left: 10px;
      width: 40px;
      height: 40px;
      border-radius: 4px;
      border: 1px solid #a0a0a0;
      align-items: center;
      justify-content: center;
    }
    .total,
    .totalColumn {
      color: #7d7c7c;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      gap: 8px;
      margin-right: 15px;
      align-items: center;
      .listPage {
        width: 130px;

        & span {
          border: none;
          color: #5a5a5a;

          &:hover {
            color: #000000;
          }
        }
        & ul {
          width: 50px;
          margin-top: 10px;
          border: 1px solid #f0f0f0;
        }
      }
    }
    .hr {
      width: 1px;
      height: 30px;
      background: #d0d0d0;
      margin-right: 15px;
    }
  }
}

@media only screen and (max-width: 460px) {
  .headRight {
    width: 100%;
    .total,
    .hr,
    .totalColumn {
      display: none !important;
    }
  }
}
