.cover {
  display: flex;
  width: 100vw;
  background: #f0f0f0;

  .container {
    width: calc(100% - 280px);
    background: #f0f0f0;
    overflow: auto;

    .loader {
      max-width: 1180px;
      padding: 30px 30px 0;
      margin: 0 auto;
    }

    .contentContainer {
      padding: 30px;
      padding-right: 0;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      gap: 15px;
      height: 100vh;
      width: min-content;

      @media screen and (min-width: 2400px) {
        width: min-content;
      }
    }

    .head {
      display: flex;
      justify-content: space-between;
      padding-right: 30px;

      .headLeft {
        display: flex;

        .dropdown {
          min-width: 200px;
          max-width: 400px;

          > span {
            border: none;
            min-width: 316px;

            &:hover {
              > span,
              div {
                color: #5a5a5a;
              }

              span:last-of-type {
                &::before,
                &::after {
                  border-color: #5a5a5a;
                }
              }
            }

            > span,
            div {
              font-weight: 500;
              font-size: 24px;
              color: #000000;
              border: none;
              padding: 0;
              max-width: 400px;
              overflow: hidden;
              text-overflow: ellipsis;

              svg {
                display: none;
              }
            }

            span:last-of-type {
              right: -10px;

              &::before,
              &::after {
                border-color: #000000;
              }
            }
          }
        }

        .title,
        .titleTags {
          font-weight: 500;
          font-size: 24px;
          display: flex;
          align-items: center;
          color: #000000;
        }
        .titleTags {
          margin: 0 !important;
        }

        .searchBlockExpandedSmart,
        .searchBlockExpanded {
          display: flex;
          gap: 10px;
          background: #f0f0f0;

          & input {
            width: 180px;
            background: transparent;
            border: none;
            outline: none;
          }

          .searchContent {
            width: 220px;
            gap: 12px;
            height: 40px;
            border-radius: 4px;
            display: flex;
            align-items: center;
            background: #f0f0f0;
            padding-left: 12px;
            border: 1px solid #a0a0a0;
            padding-right: 12px;

            & svg g path {
              fill: #a0a0a0;
            }

            input {
              color: #000000;
            }
          }
        }

        .total {
          color: #7d7c7c;
          display: flex;
          font-size: 14px;
          font-weight: 400;
          margin-left: 15px;
          align-items: center;
        }

        .searchResultsSmart,
        .searchResults {
          display: flex;

          .searchText {
            display: flex;
            gap: 10px;
            color: #a0a0a0;
          }
        }
      }

      .headRight {
        display: flex;
        align-items: center;
        height: 40px;

        .loadingContainer {
          background-color: #e8e8e8;
          border-radius: 4px;
          height: 40px;
          width: 190px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          margin-right: 10px;
          font-size: 14px;
          font-weight: 500;

          .close {
            display: none;
            width: 16px;
            height: 13px;
            cursor: pointer;
          }

          &:hover {
            .circleLoader {
              display: none;
            }

            .close {
              display: block;

              &:hover {
                path {
                  fill: #ff4f4f;
                }
              }
            }
          }
        }

        .antdDrop {
          display: flex;
          margin-left: 10px;
        }
      }
    }

    .table {
      display: flex;
      gap: 2px;
      flex-direction: column;
      width: inherit;
      width: fit-content;
    }
  }

  .personTable {
    padding: 20px;
    display: flex;
    align-items: center;
    height: 60px;
    border-radius: 4px;
    background: #ffffff;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
    gap: 20px;

    & div,
    svg {
      cursor: pointer;
    }

    .smallArrow {
      margin-left: -15px;
    }

    input[type='checkbox']:before {
      border-radius: 4px;
      border: 1px solid #a0a0a0;
    }

    input[type='checkbox']:checked {
      content: url('../../Assets/icons/done.svg');
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    .selected {
      content: url('../../Assets/icons/doneMinus.svg');
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    .all {
      content: url('../../Assets/icons/done.svg');
      cursor: pointer;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }

    button {
      border: none;
      display: flex;
      align-items: center;
      border-radius: 4px;
    }

    .tagBtn {
      border-radius: 4px;
      border: 1px solid #a0a0a0;
      padding: 7px 14px;
      color: #000000;
      cursor: pointer;
      gap: 10px;

      .back {
        transform: rotate(90deg);

        & g g path {
          fill: #000000;
        }
      }
    }
  }

  .personTable > div {
    color: #7d7c7c;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  input {
    min-width: 20px;
    min-height: 20px;
    cursor: pointer;
  }

  .img {
    min-width: 40px;
    max-width: 40px;
    display: flex;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    min-height: 40px;
    line-height: normal;
    color: #ffff;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
  }

  .tagsCover {
    display: flex;
  }

  .tags {
    display: flex;
    width: max-content;
  }

  & div > .tag {
    min-width: fit-content;
    border-radius: 4px;
    background: #f0f0f0;
    padding: 10px;
    display: flex;
    align-items: center;
    margin-right: 5px;
    gap: 5px;
    height: 30px;

    & button {
      border: none;
      margin: 0;
      cursor: pointer;
    }
  }

  .cross {
    width: 16px;
    height: 16px;
  }

  .nameImg {
    width: 215px;
    height: 30px;
    font-size: 14px;
    font-weight: 400;
  }

  .points {
    display: flex;
    flex-direction: column;

    .rotated {
      rotate: 180deg;
    }
  }
}

@media only screen and (max-width: 768px) {
  .cover {
    .container {
      .head {
        display: flex;
        flex-direction: column;
        align-items: center;

        .headRight {
          width: 100%;
          justify-content: flex-end;
        }

        .headLeft {
          width: 100%;
          justify-content: flex-start;
          display: flex;
          flex-direction: column;

          .title {
            justify-content: center;
            width: 100%;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 460px) {
  .cover {
    .container {
      padding: 30px;

      .head {
        .headLeft {
          .title {
            justify-content: center;
            width: 100%;
            margin-bottom: 30px;
          }
        }
      }
    }
  }
}

.menuItem span {
  display: flex;
  align-items: center;
  gap: 10px;
  & .back {
    rotate: -90deg;
  }
}

.menuItem {
  svg {
    fill: #a0a0a0;
  }

  &:hover {
    svg {
      fill: #3e3e3e;
    }
  }
}

.tagsSwich {
  display: flex;
  flex-direction: column;
  gap: 21px;
  & p {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    margin: 0;
    color: #a0a0a0;
  }
}

.options {
  min-width: 300px !important;
  padding-bottom: 10px;

  ul {
    > li {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .createButton {
    padding: 10px;
    border-top: 1px solid #e8e8e8;
    width: 100%;

    .button {
      width: 100% !important;
    }
  }

  .option {
    width: 100%;
    height: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #3e3e3e;
    border-bottom: 2px solid #e8e8e8;
    padding: 0 10px 0 30px;
  }
}

.leftSmartList {
  left: 30px;

  @media screen and (max-width: 1500px) {
    left: 170px;
  }
}

.bottomSpace {
  min-height: 15px;
}
