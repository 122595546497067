.container {
  height: 120px;
  background-color: #fafafa;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-right: 30px;
  gap: 30px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  overflow: hidden;

  .image {
    flex: 1;
    height: 100%;
    background: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 110px;
    max-width: 120px;

    .broadcastPreviewImage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      padding: 0px 10px;
      background: white;
    }

    .emptyImage {
      background-color: #d1d5e4;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: 36px;
        height: 28px;

        path {
          fill: #9096b0;
        }
      }
    }
  }

  .info {
    flex: 1.3;
    display: flex;
    flex-direction: column;
    row-gap: 5px;

    .line {
      display: flex;
      align-items: center;
      gap: 10px;
      width: fit-content;

      .name {
        font-size: 20px;
        font-weight: 500;
      }

      .sender,
      .list {
        color: #a0a0a0;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }

  .scheduleContent {
    .scheduleText {
      display: flex;
      gap: 10px;
      align-items: center;
      position: relative;
      right: 20px;

      .clockIcon {
        width: 16px;
        height: 16px;
        fill: #a0a0a0;
      }

      .text {
        color: #a0a0a0;
      }
    }

    .scheduledDate {
      margin-left: 5px;
    }
  }

  .stats {
    flex: 1.2;
    display: flex;
    justify-content: space-between;
    max-width: 200px;

    .stat {
      display: flex;
      flex-direction: column;
      justify-content: center;
      row-gap: 5px;
      align-items: center;

      svg {
        height: 16px;
        width: 16px;

        path {
          fill: #a0a0a0;
        }
      }

      .statAmount {
        font-size: 14px;
        text-align: center;
      }

      .emptyStat {
        color: #a0a0a0;
      }
    }
  }

  .options {
    flex: 0.2;
    max-width: 40px;
  }

  &:hover {
    background-color: #ffffff;
  }
}
