.loaderContainer {
  height: 100%;
  display: grid;
  place-items: center;
}

.container {
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 30px;

  .emptyContainer {
    min-height: 150px;
  }

  .backLink {
    position: absolute;
    left: 30px;
    top: 30px;
  }

  .header {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    align-items: center;
    max-width: 320px;

    .mailIcon {
      width: 32px;
      height: 32px;
      fill: #4957d8;
    }

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .subtitle {
      font-size: 14px;
      font-weight: 400;
      color: #a0a0a0;
      text-align: center;
    }
  }

  .form {
    margin-top: 55px;
    // flex-grow: 1;
    width: 420px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    .formElement {
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      .dropdown {
        > span {
          height: 40px;
        }
      }

      .input {
        height: 40px;
      }
    }
  }

  .buttonContainerVertical {
    display: flex;
    margin: 20px auto 0;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .buttonContainer {
    display: flex;
    column-gap: 20px;
    margin-top: 20px;
    justify-content: center;

    .backIcon {
      transform: rotate(180deg);
    }
  }
}
