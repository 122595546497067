.pageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 5px;
  max-width: 350px;
  text-align: center;

  .mailIcon {
    width: 32px;
    height: 32px;

    path {
      fill: #4719ff;
    }
  }

  .title {
    font-size: 24px;
    color: #000000;
    font-weight: 600;
  }

  .subtitle {
    font-size: 14px;
    color: #a0a0a0;
    font-weight: 300;
    max-width: 300px;
  }
}

.templates {
  justify-content: center;
}
