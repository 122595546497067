.popup {
  width: 100%;
  max-width: 600px;

  .container {
    padding: 30px 0 20px;

    .title {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
    }

    .buttonsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-top: 25px;
    }

    .divider {
      color: #d0d0d0;
    }

    .section {
      margin-top: 20px;
      padding: 0 40px;

      .toggleTitle {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      .toggleDescription {
        color: #7d7c7c;
      }

      .subtitle {
        font-size: 16px;
        font-weight: 500;
      }

      .inputsContainer {
        margin-top: 20px;
        display: flex;
        align-items: center;
        gap: 20px;

        .inputContainer {
          flex: 1;

          .addedTags {
            overflow: auto;
            margin-top: 10px;
            display: flex;
            max-width: 250px;
            gap: 5px;
            min-height: 30px;
            white-space: nowrap;

            .tag {
              border-radius: 4px;
              background: #f0f0f0;
              padding: 10px;
              display: flex;
              align-items: center;
              gap: 5px;
              height: 30px;

              .crossBtn {
                border: none;
                cursor: pointer;
                background: transparent;
              }
            }

            &::-webkit-scrollbar-track {
              background-color: #fafafa;
            }

            &::-webkit-scrollbar {
              background-color: #fafafa;
              height: 5px;
            }

            &::-webkit-scrollbar-thumb {
              background-color: #d0d0d0;
            }
          }

          .inputTitle {
            margin-bottom: 15px;
          }

          .inputSubtitle {
            color: #7d7c7c;
            margin-bottom: 15px;
          }

          .nameInput {
            height: 40px;
          }

          .input {
            position: relative;
            max-width: 250px;

            .attention {
              position: absolute;
              top: -10px;
              right: -5px;
              width: 24px;
              height: 24px;
            }
          }

          .error {
            input {
              border-color: #ff4f4f !important;
            }
          }

          .dropdown {
            > span {
              height: 40px;
            }
          }
        }
      }
    }
  }
}

.addSender {
  border-top: 1px solid #e8e8e8;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  .plusIcon {
    path {
      fill: #000000;
    }
  }

  &:hover {
    background-color: #f0f0f0;
  }
}

.searchContainer {
  padding: 10px;

  .search {
    height: 30px;
  }
}
