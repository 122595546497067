.container {
  flex-grow: 1;
  height: 100dvh;
  overflow: auto;
  margin: 0 auto;
  background-color: #f0f0f0;

  .loading {
    max-width: 1180px;
    padding: 30px 30px 0;
    margin: 0 auto;
  }

  .contentContainer {
    max-width: 1180px;
    padding: 30px;
    margin: 0 auto;

    .generationTemplateElements {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(220px, auto));
      justify-content: space-between;
      gap: 20px;
    }
  }
}
