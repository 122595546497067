.popup {
  width: 100%;
  max-width: 600px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    font-size: 24px;
    line-height: 17px;
    margin-bottom: 17px;
  }

  .subtitle {
    color: #7d7c7c;
    max-width: 194px;
    text-align: center;
    font-weight: 400;
    margin-bottom: 33px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 322px;

    .formInput {
      height: 40px;
      margin-bottom: 20px;
    }

    .phoneInput {
      width: 100%;

      input {
        border: 1px solid;
        border-radius: 4px;
        border-color: #a0a0a0;
        padding-left: 15px;
        padding-right: 15px;
        height: 100%;
        background-color: transparent;
      }

      input:focus {
        border-color: #6674f4;
        outline: none;
      }

      input::placeholder {
        color: #c7bfbf;
      }
    }

    .select {
      width: 100%;

      > div {
        border-color: #a0a0a0 !important;
        box-shadow: none !important;
        height: 40px;
        border-radius: 4px !important;

        &:hover {
          border-color: #000000;
        }

        &:focus {
          border-color: #6674f4 !important;
        }
      }
    }

    .submitButton {
      margin-top: 60px;
    }
  }
}

.addTagButton {
  padding: 0 10px;
  cursor: pointer;
  color: #000000;
}
