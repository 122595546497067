.templates {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;

  .templateItem {
    width: 190px;
    cursor: pointer;

    &:hover,
    &.active {
      .emptyTemplateContent {
        border-color: #3e3e3e;
      }
    }

    .templateName {
      text-align: center;
    }

    .emptyTemplateContent {
      height: 210px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 4px;

      .templateImage {
        width: 100%;
        height: 100%;
      }

      .blankTemplate {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
      }

      .templateItems {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }

      .emptyTitle {
        height: 10px;
        border-radius: 2px;
        background-color: #d0d0d0;
      }

      .emptyText {
        height: 6px;
        border-radius: 2px;
        background-color: #e8e8e8;
      }

      .emptyImage {
        background-color: #d0d0d0;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;

        .imageIcon {
          width: 26px;
          height: 26px;

          path {
            fill: #a0a0a0;
          }
        }
      }

      .emptyButtonContainer {
        display: flex;

        .emptyButton {
          background-color: #d0d0d0;
          border-radius: 2px;
          width: 56px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          .emptyButtonText {
            height: 4px;
            width: 32px;
            background-color: #e8e8e8;
            border-radius: 2px;
          }
        }
      }
    }
  }
}
