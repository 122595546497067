.sequenceEditor {
  height: 100%;

  .customHeader {
    display: flex;
    gap: 10px;

    .headerItem {
      display: flex;
      gap: 5px;
      background-color: #ffffff;
      border-radius: 4px;
      padding: 5px 10px;

      .editableLabel {
        > div,
        input {
          min-width: 10px;
        }
      }

      .itemName {
        color: #7d7c7c;
      }

      .itemInput {
        width: fit-content;
        .arrows {
          top: 0;
        }

        input {
          width: 40px;
          border: none;
        }
      }

      .dropdown {
        min-width: 100px;
        > span {
          height: 25px;
          border: none;
        }
      }
    }
  }

  .eye {
    width: 22px;
    height: 18px;

    path {
      fill: #000000;
    }
  }

  .editorContainer {
    display: flex;
    height: calc(100% - 60px);
    position: relative;
    overflow: hidden;

    .editor {
      position: relative;
      flex-grow: 1;
      overflow: auto;
    }
  }

  .confirmButtonPlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .placeholderIcon {
      width: 14px;

      path {
        fill: #ffffff;
      }
    }
  }

  .additionalButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }

  .ghostIcon {
    path {
      fill: #000000;
    }
  }

  .emailPrefixContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #000000;
  }
}
