.head {
  display: flex;
  justify-content: space-between;
  .headLeft {
    display: flex;

    .search {
      width: 220px;
      gap: 12px;
      height: 40px;
      border-radius: 4px;
      display: flex;
      align-items: center;

      input {
        background: transparent;

        &:focus {
          background-color: #ffffff;
        }
      }
    }

    .totalTags,
    .total {
      color: #7d7c7c;
      display: flex;
      font-size: 14px;
      font-weight: 400;
      margin-left: 15px;
      align-items: center;
    }
    .searchResultsSmart,
    .searchResults {
      display: flex;
      .searchText {
        display: flex;
        gap: 10px;
        color: #a0a0a0;
      }
    }
    .smart {
      display: flex;
      overflow: auto;
      max-width: calc(100vw - 525px);
      gap: 2px;
      .pageAll {
        cursor: pointer;
        width: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 13px;
        font-weight: 400;
        .selectedOption {
          border-radius: 4px;
          background: #4957d8;
          width: 80px;
          height: 2px;
          margin-top: 14px;
        }
        .pageOption {
          border-radius: 4px;
          background: #d0d0d0;
          width: 80px;
          height: 2px;
          margin-top: 14px;
        }
        &:hover {
          .pageOption {
            background: #000000;
          }
        }
      }
      .pageNew {
        padding-top: 13px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        font-weight: 400;
        align-items: center;
        .pageNewInner {
          padding-left: 24px;
          display: flex;
          gap: 6px;
          align-items: center;
        }
        &:hover {
          .cross {
            opacity: 1;
          }
        }
        .cross {
          width: 16px;
          height: 16px;
          opacity: 0;
          transition: 0.3s ease-in-out;
          g path {
            fill: #a0a0a0;
          }
        }
        .selectedOption {
          border-radius: 4px;
          background: #4957d8;
          width: -webkit-fill-available;
          height: 2px;
          margin-top: 14px;
        }
        .pageOption {
          border-radius: 4px;
          background: #d0d0d0;
          width: -webkit-fill-available;
          height: 2px;
          margin-top: 14px;
        }
        &:hover {
          .pageOption {
            background: #000000;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  .head {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 182px;
  }
  .total {
    position: absolute;
    top: 64px;
    left: -13px;
  }
  .totalTags {
    position: absolute;
    width: 100px;
    top: 10px;
    left: -13px;
  }
  .searchContentTag {
    position: absolute;
    top: -58px;
  }
}

@media only screen and (max-width: 460px) {
  .head {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 169px;
  }
  .searchContent {
    width: calc(100vw - 230px) !important;
  }
}
