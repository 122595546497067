.BroadCastEditor {
  height: 100%;

  .buttonsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: absolute;
    top: 120px;
    left: 10px;
    z-index: 10;

    .menuButton {
      display: flex;
      align-items: center;

      span {
        height: 20px;
      }
    }
  }

  .EditorContainer {
    display: flex;
    height: calc(100% - 60px);
    position: relative;
    overflow: hidden;

    .SideMenu {
      display: flex;
      gap: 5px;
      height: 100%;
      overflow-y: auto;
      position: relative;
      transition: transform 1s;

      &.collapsed {
        transform: translateX(-450px);
        position: absolute;
      }
    }

    .Editor {
      position: relative;
      flex-grow: 1;
      overflow: auto;
    }
  }

  .confirmButtonPlaceholder {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .placeholderIcon {
      width: 14px;

      path {
        fill: #ffffff;
      }
    }
  }

  .additionalButtons {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }

  .ghostIcon {
    path {
      fill: #000000;
    }
  }

  .emailPrefixContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 14px;
    color: #000000;
  }
}

.loaderContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  z-index: 100;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
