.container {
  background-color: #f0f0f0;
  padding: 10px 0;
  overflow: auto;
  margin: 30px 0 20px;
  flex: 1;

  .tableContainer {
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    gap: 2px;

    .tableRow {
      display: grid;
      width: 100%;
      height: 60px;
      min-height: 60px;
      align-items: center;
      grid-template-columns: 1.5fr 2fr 1.5fr;
      background-color: #fafafa;
      border-radius: 4px;
      padding: 0px 15px 0px 20px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

      .title {
        color: #7d7c7c;
        font-weight: 400;
      }
    }
  }
}
