.container {
  position: absolute;
  width: 100dvw;
  height: 100dvh;
  z-index: 1000;
  top: 0;
  left: 0;

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .coverContainer {
    height: 100%;
    margin: 0 auto;
    width: 100%;
    max-width: calc(100% - 280px);
    z-index: 2;
    position: relative;
    transform: translate(140px, 0);

    @media screen and (max-width: 1500px) {
      transform: translate(0, 0);

      .smartCover {
        right: -140px !important;
      }
    }

    .smartCover {
      padding: 20px;
      display: flex;
      position: absolute;
      top: 83px;
      right: 0px;
      z-index: 4;
      width: 315px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);
      flex-direction: column;
      justify-content: space-between;
      & div > div {
        display: flex;
        gap: 10px;
      }
      & div div svg g path {
        fill: black;
      }
      .close {
        display: none;
        width: 20px;
        height: 20px;
        position: absolute;
        right: 20px;
        g {
          path {
            fill: #a0a0a0;
          }
        }
      }
      .filterText {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      .filterTopText {
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        margin-top: -7px;
        color: #000000;
      }
      .filterBottomtext {
        color: #a0a0a0;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }
      .hr {
        width: 315px;
        height: 1px;
        background: #e8e8e8;
        margin: 20px 0;
        margin-left: -20px;
      }
      & p {
        margin: 10px 0 15px 0;
        font-size: 14px;
        font-style: normal;
        color: #000000;
        display: flex;
        padding: 0;
        font-weight: 400;
        & span {
          margin: 0 0 0 4px;
          color: #ff4f4f;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
        }
      }

      .excludeTagsContainer {
        margin-bottom: 10px;
      }

      .filterInputTags,
      .filterInput {
        padding: 15px;
        width: 275px;
        height: 40px;
        border-radius: 4px;
        border: 1px solid #a0a0a0;
        outline: none;
        color: #000000;
      }
      .filterInputTags {
        position: absolute;
        top: 240px;
      }
      .filterInputTags:focus,
      .filterInput:focus {
        border: 1px solid #6674f4;
      }
      .filterBtns {
        display: flex;
        gap: 15px;
        & button {
          height: 40px;
        }
      }
    }

    .secondMenu {
      border-radius: 0 0 4px 4px;
    }

    .addedTags {
      overflow: auto;
      margin-top: 10px;
      flex-wrap: wrap;

      .tag {
        border-radius: 4px;
        background: #f0f0f0;
        border: 1px solid #f0f0f0;
        padding: 7px 10px;
        display: flex;
        align-items: center;
        gap: 5px;
        height: 30px;
        max-width: 275px;

        &:hover {
          background-color: #fafafa;
        }

        .tagText {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        }

        .crossBtn {
          border: none;
          cursor: pointer;
          background: transparent;

          &:hover {
            path {
              fill: #ff4f4f;
            }
          }
        }
      }
    }

    .addedTags::-webkit-scrollbar-track {
      background-color: #fafafa;
    }

    .addedTags::-webkit-scrollbar {
      background-color: #fafafa;
      height: 5px;
    }

    .addedTags::-webkit-scrollbar-thumb {
      background-color: #d0d0d0;
    }

    @media only screen and (max-width: 600px) {
      .close {
        display: block !important;
      }
    }
  }
}

.searchContainer {
  padding: 10px;

  .search {
    height: 30px;
  }
}
