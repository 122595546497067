.container {
  height: 120px;
  background-color: #fafafa;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  gap: 30px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

  .left {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 30px;

    .image {
      flex: 1;
      height: 100%;
      background: #f0f0f0;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 110px;
      max-width: 120px;

      .previewImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        background: white;
      }
    }

    .cover {
      height: 100%;
      background-color: #e6e0f4;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;

      .mailSequenceIcon {
        width: 36px;
        height: 36px;

        path {
          fill: #8573bf;
        }
      }
    }

    .info {
      flex: 1.3;
      display: flex;
      flex-direction: column;
      row-gap: 5px;

      .line {
        display: flex;
        align-items: center;
        gap: 10px;
        width: fit-content;

        .name {
          font-size: 20px;
          font-weight: 600;
        }

        .tag {
          display: flex;
          align-items: center;
          gap: 5px;
          color: #a0a0a0;
          white-space: nowrap;

          .tagIcon {
            path {
              fill: #a0a0a0;
            }
          }
        }
      }
    }
  }
  .right {
    display: flex;
    align-items: center;
    gap: 45px;

    .stats {
      display: flex;
      align-items: center;
      gap: 40px;

      .stat {
        display: flex;
        flex-direction: column;
        gap: 10px;
        font-size: 12px;
        align-items: center;

        .statIcon {
          width: 16px;
          height: 16px;

          path {
            fill: #a0a0a0;
          }
        }
      }
    }

    .buttonsContainer {
      display: flex;
      align-items: center;
      gap: 20px;

      .options {
        flex: 0.2;
        max-width: 40px;
      }
    }
  }

  &:hover {
    background-color: #ffffff;
  }
}
