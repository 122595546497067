.personTable {
  padding: 20px;
  display: flex;
  align-items: center;
  height: 60px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.05);
  gap: 20px;
  & div,
  svg {
    cursor: pointer;
  }
  .smallArrow {
    margin-left: -15px;
  }
  input[type='checkbox']:before {
    border-radius: 4px;
    border: 1px solid #a0a0a0;
  }
  input[type='checkbox']:checked {
    content: url('../../../Assets/icons/done.svg');
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .selected {
    content: url('../../../Assets/icons/doneMinus.svg');
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  .all {
    content: url('../../../Assets/icons/done.svg');
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  button {
    border: none;
    display: flex;
    align-items: center;
    border-radius: 4px;
  }
  .tagBtn {
    border-radius: 4px;
    border: 1px solid #a0a0a0;
    padding: 7px 14px;
    color: #000000;
    cursor: pointer;
    gap: 10px;
    .back {
      transform: rotate(90deg);
      & g g path {
        fill: #000000;
      }
    }
  }
}
.personTable > div {
  color: #7d7c7c;
  display: flex;
  align-items: center;
  gap: 10px;
}
.nameImg {
  width: 215px;
  height: 30px;
  font-size: 14px;
  font-weight: 400;
}
.points {
  display: flex;
  flex-direction: column;

  .arrow {
    &:hover {
      path {
        fill: #000;
      }
    }
  }

  .rotated {
    rotate: 180deg;
  }

  .activeArrow {
    path {
      fill: #4719ff;
    }
  }
}
.emailTable,
.phoneTable,
.addressTable {
  &:hover {
    border-bottom: none !important;
  }
}
.emailTable {
  outline: none;
  border: none;
  min-width: 220px;
  max-width: 220px;
  min-height: 30px;
  border-bottom: transparent;
  transition: 0.1s ease-in-out;
  &:hover {
    border-bottom: 1px solid #d0d0d0;
  }
  &:focus {
    border-bottom: 1px solid #6674f4;
  }
}
.phoneTable {
  outline: none;
  border: none;
  min-width: 140px;
  max-width: 140px;
  min-height: 30px;
  border-bottom: transparent;
  transition: 0.1s ease-in-out;
  &:hover {
    border-bottom: 1px solid #d0d0d0;
  }
  &:focus {
    border-bottom: 1px solid #6674f4;
  }
}
.addressTable {
  outline: none;
  border: none;
  max-width: 550px;
  min-width: 550px;
  min-height: 30px;
  border-bottom: transparent;
  transition: 0.1s ease-in-out;
  &:hover {
    border-bottom: 1px solid #d0d0d0;
  }
  &:focus {
    border-bottom: 1px solid #6674f4;
  }
}

.tagMenu {
  max-height: 320px;
  width: 212px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 4px 4px 0 0;
  .tagDisplay {
    height: 40px;
    color: var(--000000, #000);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}
.tagMenu::-webkit-scrollbar-track {
  background-color: #fafafa;
}

.tagMenu::-webkit-scrollbar {
  background-color: #fafafa;
  width: 5px;
}

.tagMenu::-webkit-scrollbar-thumb {
  background-color: #d0d0d0;
}

.secondMenu {
  .popupInput {
    display: flex;

    & > div {
      width: 188px !important;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #a0a0a0;
      padding: 10px 0 10px 10px;
      display: flex;
      align-items: center;

      input {
        border: none;
        background: transparent;
        outline: none;
        width: 143px;
      }
      .inputButton {
        cursor: pointer;
        min-width: 30px;
        min-height: 30px;
        border-radius: 0px 4px 4px 0px;
        background: #000000;
        display: flex;
        justify-content: center;
        transition: 0.3s ease-in-out;
        align-items: center;
        margin-left: 9px;
        border: none;
        &:hover {
          background: #3e3e3e;
        }
        & svg g g path {
          fill: #ffffff;
        }
      }
    }
  }
}
