@import '../constants.scss';

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  cursor: pointer;
  column-gap: 10px;
  line-height: 25px;
  border-radius: 4px;

  .text {
    margin-top: -1px;
  }

  &.solid {
    &.light {
      color: $white;
      background-color: $black;
      &:hover {
        background-color: $grey-200;
      }
    }
    &.dark {
      color: $grey-600;
      background-color: $grey-200;
      &:hover {
        color: $white;
        background-color: $grey-100;
      }
    }
  }

  &.stroke {
    background-color: transparent;

    &.light {
      color: $grey-200;
      border: 1px solid $grey-500;
      &:hover {
        border-color: $black;
      }
    }
    &.dark {
      color: $grey-600;
      border: 1px solid $grey-100;
      &:hover {
        color: $white;
        border-color: $white;
      }
    }
  }

  &.highlighted {
    color: $white;
    background-color: $accent-500;
    &:hover {
      background-color: $accent-700;
    }
  }

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
  }

  svg {
    fill: currentColor;

    path {
      fill: currentColor;
    }
  }
}
