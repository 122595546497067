.menuWrapper {
  height: 100vh;
  width: 280px;
  min-width: 280px;
  background-color: var(--side-bar-background);
  position: relative;
  z-index: 10;
  padding-top: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 0;
  justify-content: space-between;
  box-shadow: 10px 0px 0px 0px rgba(0, 0, 0, 0.03);
  overflow: auto;

  .topItem {
    width: 100%;
    margin-bottom: 4px;

    svg {
      margin-right: 4px;
    }

    .logo {
      padding: 0 25px 15px;
      border-bottom: 1px solid var(--side-bar-divider);
      margin-bottom: 15px;
      display: flex;
      justify-content: center;

      img {
        max-height: 100px;
        max-width: 250px;
        object-fit: contain;
        cursor: pointer;
      }
    }

    .agencyItem {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .dots {
        display: none;
      }

      &:hover {
        .dots {
          display: flex;
        }
      }
    }

    .menuItems {
      width: 100%;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      position: relative;

      .pageName {
        text-transform: capitalize;
      }

      .activeItem {
        background-color: var(--side-bar-app-selected) !important;
        color: var(--side-bar-selected-text);

        svg {
          path {
            fill: var(--side-bar-selected-text) !important;
          }
        }
      }

      .agency {
        position: relative;
        border-bottom: 1px solid var(--side-bar-divider);
        margin: 15px 12px;
      }

      .agencyLogo {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }

      .menuIcon {
        min-width: 30px;
        display: flex;
        justify-content: center;

        &.outreach {
          svg {
            width: 26px;
            height: 26px;
          }
        }
      }
    }
  }

  .agencyImage {
    display: flex;
    align-items: center;

    .thumbnailBox {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;
      background-color: #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 0;

      .photoPlaceholder {
        width: 20px;
        height: 20px;
        margin-right: 0;

        path {
          fill: #a0a0a0 !important;
        }
      }
    }
  }

  .bottomItems {
    padding: 15px;
    width: 100%;
    display: flex;
    border-top: 1px solid var(--side-bar-divider);
    justify-content: space-between;

    .menuItem {
      flex-direction: column;
      max-width: 84px;
      align-items: center;
      justify-content: center;
      min-height: 56px;

      span {
        font-size: 12px;
        margin-left: 0;
        margin-top: 10px;
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
  .badge {
    margin-left: 4px;

    path {
      fill: var(--alerts-success) !important;
    }
  }
  .addAppDivider {
    margin: 15px 0;
    border-top: 1px solid var(--side-bar-divider);
  }

  .menuItem {
    min-height: 50px;
    width: 100%;
    border-radius: 4px;
    padding: 0 13px;
    white-space: nowrap;
    cursor: pointer;
    color: var(--side-bar-text);
    display: flex;
    align-items: center;

    span {
      margin-left: 13px;
    }

    svg {
      path {
        fill: var(--side-bar-text);
      }
    }

    .settingsIcon {
      position: absolute;
      right: 30px;
      display: none;
      fill: var(--side-bar-text);

      &:hover {
        fill: var(--side-bar-hover-text);
      }
    }

    &:hover {
      background-color: var(--side-bar-app-hover);
      color: var(--side-bar-hover-text);

      svg {
        path {
          fill: var(--side-bar-hover-text);
        }
      }

      .settingsIcon {
        display: block;
      }
    }
  }
}
