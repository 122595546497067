.container {
  .line {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    align-items: center;
    flex-wrap: wrap;

    .sortContent {
      display: flex;
      align-items: center;
      gap: 15px;
      text-transform: capitalize;

      .sortBy {
        display: flex;
        align-items: center;
        gap: 6px;
      }

      .label {
        font-size: 14px;
        color: #a0a0a0;
        white-space: nowrap;
        font-weight: 400;
      }

      .sort {
        min-width: 140px;

        li {
          padding-left: 10px;
          min-width: 160px;

          &:hover {
            color: #5a5a5a;
          }
        }

        > span {
          border: none;
          color: #000;
          height: 30px !important;
          padding: 0 5px;
          font-weight: 400;

          &:hover {
            background-color: #ffffff;
          }

          span {
            color: #000;
          }
        }
      }
    }

    .title {
      font-size: 24px;
      font-weight: 500;
      text-transform: capitalize;
    }

    .confirmButton {
      text-transform: capitalize;
    }

    .searchBar {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;

      .search {
        width: 220px;
        gap: 12px;
        height: 40px;
        border-radius: 4px;
        display: flex;
        align-items: center;

        input {
          background: transparent;

          &:focus {
            background-color: #ffffff;
          }
        }
      }

      .total {
        font-size: 14px;
        color: #a0a0a0;
        font-weight: 400;
        text-transform: capitalize;
      }
    }
  }
}

.optionsClassName {
  text-transform: capitalize;
}
