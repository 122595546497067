.container {
  display: flex;
  height: 100%;

  .error {
    font-size: 12px;
    color: var(--alerts-error);
  }

  .backLink {
    position: absolute;
    left: 30px;
    top: 30px;
    z-index: 1;

    .backButton {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .formWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    flex-grow: 1;

    .section {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px;
      width: 100%;

      .contactsError {
        width: 100%;
        max-width: 460px;
        display: flex;
        align-items: center;
        gap: 10px;
        color: #ff4f4f;
        margin-top: 20px;

        .attention {
          width: 16px;
          height: 16px;
        }
      }

      &.firstSection {
        border-bottom: 1px solid #f0f0f0;
      }

      .icon {
        width: 32px;
        height: 32px;
        fill: #4957d8;
        margin-bottom: 5px;
      }

      .title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 5px;
      }

      .subtitle {
        font-size: 14px;
        font-weight: 400;
        color: #a0a0a0;
        margin-bottom: 10px;
      }

      .formInput {
        width: 100%;
        max-width: 460px;
        margin-top: 20px;

        .addedTags {
          overflow: auto;
          margin-top: 10px;
          flex-wrap: wrap;
          display: flex;
          gap: 5px;

          .tag {
            border-radius: 4px;
            background: #f0f0f0;
            border: 1px solid #f0f0f0;
            padding: 7px 10px;
            display: flex;
            align-items: center;
            gap: 5px;
            height: 30px;
            max-width: 275px;

            &:hover {
              background-color: #fafafa;
            }

            .tagText {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              display: block;
            }

            .crossBtn {
              border: none;
              cursor: pointer;
              background: transparent;

              &:hover {
                path {
                  fill: #ff4f4f;
                }
              }
            }
          }
        }

        .inputLabel {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        &.noMargin {
          margin-top: 0;
        }

        &.nameInput {
          margin-bottom: 25px;
        }

        .dropdown {
          margin-top: 5px;

          > span {
            height: 40px;
          }

          .autoFunnelSender {
            .description {
              display: none;
            }
          }
        }

        .input {
          margin-top: 5px;
          height: 40px;
        }

        .verificationWarning {
          margin-top: 5px;
          font-size: 20px;
        }
      }

      .secondSectionWrapper {
        width: 100%;
        max-width: 460px;
      }

      .contactsCount {
        margin-top: 20px;
        width: 100%;
        max-width: 460px;
      }

      .contactCountMessage {
        color: #a0a0a0;
        display: flex;
        align-items: center;
      }

      .radioButtonGroup {
        display: flex;
        align-items: center;
        column-gap: 15px;
        margin-top: 25px;
        width: 100%;
        max-width: 460px;

        .radioButton {
          white-space: nowrap;
        }
      }

      .dateContainer {
        position: relative;
        display: flex;
        align-items: center;
        column-gap: 10px;
        margin-top: 15px;
        height: 40px;

        .dateInput {
          max-width: 150px;
        }

        .timeInput {
          width: 100px;

          & > div {
            left: 10px;
          }

          input {
            padding-left: 34px;
          }
        }

        .timezoneDropdown {
          & > span {
            & > span:first-of-type {
              width: 146px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          ul {
            max-width: 400px;
            transform: translateX(-50%);

            li {
              line-height: unset;
            }
          }
        }
      }

      .launchButton {
        margin-top: 50px;
      }
    }
  }

  .previewWrapper {
    flex-grow: 1;
    max-width: 600px;
    background-color: #f9f9f9;
    padding: 30px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    min-width: fit-content;
    border-left: 1px solid #d0d0d0;

    .title {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 20px;
    }

    .buttonsWrapper {
      display: flex;
      column-gap: 10px;
    }
  }

  .previewContainer {
    margin-top: 30px;
    max-width: 100%;
    padding: 0 40px 0;
  }
}

.addSender {
  border-top: 1px solid #e8e8e8;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  .plusIcon {
    path {
      fill: #000000;
    }
  }

  &:hover {
    background-color: #f0f0f0;
  }
}

.dropdownOptionsClassName {
  width: 380px !important;
}

.autoFunnelSender {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .description {
    max-width: 170px;
    text-align: end;
    font-size: 12px;
    color: #a0a0a0;
  }
}

.searchContainer {
  padding: 10px;

  .search {
    height: 30px;
  }
}
