@tailwind base;
@tailwind components;
@tailwind utilities;
/* @layer base {
  * {
    @apply border-border;
  }

  body {
    @apply font-sans antialiased bg-background text-foreground;
  }
} */

/* this variables is coming from @replit/vite-plugin-shadcn-theme-json */
/* since we don't have vit we will copy those for now  */
:root {
  --background: 0 0% 100%;
  --foreground: 20 14.3% 4.1%;
  --muted: 60 4.8% 95.9%;
  --muted-foreground: 25 5.3% 44.7%;
  --popover: 0 0% 100%;
  --popover-foreground: 20 14.3% 4.1%;
  --card: 0 0% 100%;
  --card-foreground: 20 14.3% 4.1%;
  --border: 20 5.9% 90%;
  --input: 20 5.9% 90%;
  --primary: 252 100% 54.9%;
  --primary-foreground: 221 8% 97%;
  --secondary: 60 4.8% 95.9%;
  --secondary-foreground: 24 9.8% 10%;
  --accent: 60 4.8% 95.9%;
  --accent-foreground: 24 9.8% 10%;
  --destructive: 0 84.2% 60.2%;
  --destructive-foreground: 60 9.1% 97.8%;
  --ring: 20 14.3% 4.1%;
  --radius: 0.375rem;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&display=swap');
@font-face {
  font-family: 'Roboto';
  src: url('./Assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./Assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Ropa';
  src: url('./Assets/fonts/RopaSans-Regular.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Audiowide';
  src: url('./Assets/fonts/Audiowide-Regular.ttf') format('truetype');
  font-weight: normal;
}

hr {
  border: 1px solid darkgray;
  margin: 10px 0;
}

body {
  margin: 0;
  font-family: 'Inter', arial !important;
  font-style: normal;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent;
}
html,
body,
.App {
  height: 100%;
}
#root {
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  padding-left: 15px;
}
#nav-custom-icon {
  margin-right: 0px;
}
.button-container {
  display: none;
  position: absolute;
}
.ant-table-cell:hover .button-container {
  display: inline-block;
  padding-left: 5px;
  margin-top: -5px;
}
.ant-menu-item:hover .button-container {
  display: inline-block;
  right: 20px;
}

.custom .ant-dropdown-menu {
  border-radius: 8px;
  padding: 6px 0px;
}

.nav-custom-button .ant-btn-primary {
  font-size: 45px;
  background-color: #112e48;
  border-color: #1466b1;
}

/* text editor  */
.align-left-item div {
  text-align: left;
}

.align-center-item div {
  text-align: center;
}

.align-right-item div {
  text-align: right;
}
