.dropdownOptionLabel {
  white-space: nowrap;

  .dollar {
    color: #7d7d7d;
  }
}

.attentionIcon {
  path {
    fill: #9ca3af;
  }
}
