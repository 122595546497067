.wrapper {
  width: 100%;
  max-width: 600px;

  .container {
    padding: 40px;

    .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .emailIcon {
        width: 60px;
        height: 50px;

        path {
          fill: #a0a0a0;
        }
      }

      .subjectContainer {
        height: 70px;
        width: 100%;
        max-width: 380px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 1px solid #d0d0d0;
        border-radius: 4px;
        margin: 18px 0 5px;
        font-size: 18px;

        .subtitle {
          margin-top: 0;

          .emailsCount {
            font-weight: 700;
          }
        }
      }

      .title {
        font-size: 24px;
        margin-top: 20px;
      }

      .subtitle {
        font-size: 14px;
        margin-top: 15px;
        max-width: 250px;
        text-align: center;
        color: #7d7c7c;
      }

      .buttonsContainer {
        display: flex;
        gap: 20px;
        margin-top: 45px;
      }
    }
  }
}
