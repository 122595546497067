.popup {
  width: 100%;
  max-width: 600px;

  .container {
    padding: 40px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 24px;
      font-weight: 500;
      margin-top: 20px;
    }

    .subtitle {
      color: #7d7c7c;
      margin-top: 20px;
      max-width: 315px;
      text-align: center;
    }

    .balance {
      font-size: 18px;
      margin-top: 10px;
    }

    .buttonsContainer {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-top: 35px;
    }
  }
}
