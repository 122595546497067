.pageContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  min-height: 100dvh;

  .screenContainer {
    padding: 30px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.selectedCampaign {
    background-color: #ffffff;
  }

  .backLink {
    position: absolute;
    left: 30px;
  }

  .title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 20px;
    line-height: 16px;
  }

  .subtitle {
    color: #a0a0a0;
    text-align: center;
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 60px;
    max-width: 300px;
    line-height: 20px;
  }

  .campaignsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-width: 1060px;

    .campaignElement {
      position: relative;
      width: 200px;
      height: 220px;
      background-color: #fafafa;
      border-radius: 6px;
      padding: 20px 15px;
      margin: 5px;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        cursor: pointer;
        background-color: #ffffff;
        width: 206px;
        height: 226px;
        margin: 2px;
      }

      .campaignTitle {
        font-size: 14px;
        line-height: 12px;
        margin-top: 20px;
        margin-bottom: 19px;
        text-align: center;
        font-weight: 400;
      }

      .campaignDescription {
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        text-align: center;
        max-width: 170px;
        color: #a0a0a0;
      }

      .tag {
        position: absolute;
        bottom: 0;
        background-color: #8f92a51a;
        left: 50%;
        transform: translateX(-50%);
        width: 104px;
        height: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        font-size: 12px;
        line-height: 9px;
      }
    }
  }

  .selectedCampaignTitle {
    font-size: 22px;
    margin-bottom: 15px;
    margin-top: 20px;
    line-height: 16px;
  }

  .selectedCampaignSubtitle {
    font-size: 14px;
    color: #7d7c7c;
    max-width: 218px;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 18px;
    text-align: center;
  }

  .campaignForm {
    width: 100%;
    max-width: 420px;

    .formGroup {
      margin-bottom: 20px;

      .formLabel {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 15px;
        line-height: 10px;
      }

      input {
        height: 50px;
      }
    }

    .buttonsContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: 10px;

      .back {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        color: #a0a0a0;
        margin-top: 10px;
        cursor: pointer;

        &:hover {
          color: #4957d8;

          .backIcon {
            path {
              fill: #4957d8;
            }
          }
        }

        .backIcon {
          path {
            fill: #a0a0a0;
          }
        }
      }
    }
  }

  .templatesContainer {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex: 1;

    .templatesMenu {
      max-width: 460px;
      padding: 30px 20px 0 30px;
      display: flex;
      flex-direction: column;

      .tabsContainer {
        flex: 1;
        max-height: 89vh;

        .tabTitleClassName {
          min-width: 130px;
        }

        .contentClassName {
          max-height: 100%;
          overflow: auto;
        }
      }

      .templates {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin-top: 20px;

        .templateItem {
          width: 190px;
          cursor: pointer;

          &:hover,
          &.active {
            .emptyTemplateContent {
              border-color: #4719ff;
            }
          }

          .templateName {
            text-align: center;
          }

          .emptyTemplateContent {
            height: 210px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;

            .templateImage {
              width: 100%;
              height: 100%;
            }

            .blankTemplate {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            }

            .templateItems {
              display: flex;
              flex-direction: column;
              gap: 6px;
            }

            .emptyTitle {
              height: 10px;
              border-radius: 2px;
              background-color: #d0d0d0;
            }

            .emptyText {
              height: 6px;
              border-radius: 2px;
              background-color: #e8e8e8;
            }

            .emptyImage {
              background-color: #d0d0d0;
              border-radius: 2px;
              display: flex;
              align-items: center;
              justify-content: center;

              .imageIcon {
                width: 26px;
                height: 26px;

                path {
                  fill: #a0a0a0;
                }
              }
            }

            .emptyButtonContainer {
              display: flex;

              .emptyButton {
                background-color: #d0d0d0;
                border-radius: 2px;
                width: 56px;
                height: 16px;
                display: flex;
                align-items: center;
                justify-content: center;

                .emptyButtonText {
                  height: 4px;
                  width: 32px;
                  background-color: #e8e8e8;
                  border-radius: 2px;
                }
              }
            }
          }
        }
      }

      .buttonsContainer {
        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;
        padding: 10px 0;
      }
    }
  }

  .preview {
    background-color: #e8e8e8;
    flex: 1;

    .previewContent {
      max-width: 800px;
      max-height: 100vh;
      overflow: auto;
      margin: 0 auto;
      min-height: 100%;

      > div {
        min-height: 100%;

        > div {
          min-height: 100vh;
        }
      }
    }

    .emptyPreview {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #a0a0a0;
      font-size: 16px;
      height: 100%;
      width: 100%;

      .emptyIcon {
        width: 52px;
        height: 52px;

        path {
          fill: #a0a0a0;
        }
      }
    }
  }
}
