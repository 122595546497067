.popup {
  width: 100%;
  max-width: 600px;
  padding: 40px;

  .contentContainer {
    .title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px;
      text-align: center;
    }

    .buttonsContainer {
      margin-top: 30px;
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: center;
    }

    .inputsContainer {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .inputCOntainer {
        display: flex;
        flex-direction: column;
        gap: 15px;

        .timeContainer {
          display: flex;
          align-items: center;
          gap: 10px;

          .dropdown {
            width: 235px;

            > span {
              height: 40px;
            }
          }
        }

        .input {
          &.timeInput {
            width: 66px;
          }

          input {
            height: 40px;
          }
        }
      }
    }
  }
}
