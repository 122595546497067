.container {
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: grid;
  justify-content: center;
  overflow-y: auto;
  padding-bottom: 50px;

  .contentBody {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
      0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

    .dropItem {
      background-color: rgb(178 213 245 / 18%);
      border: 3px solid rgb(88, 165, 238);
      border-radius: 4px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .text {
        color: rgb(88, 165, 238);
        font-size: 25px;
      }
    }

    .emptyTemplateContainer {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;

      .emptyText {
        max-width: 300px;
        color: #7d7c7c;
        text-align: center;
      }
    }
  }
}
