.container {
  display: grid;
  grid-template-columns: 280px auto;
  height: 100vh;
  background-color: #f0f0f0;

  .pageContainer {
    width: 100%;
    margin: 0 auto;
    overflow: auto;

    .loader {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .bannerContainer {
      height: 220px;
      margin-bottom: 60px;
      position: relative;
      display: flex;
      align-items: center;
      background-repeat: no-repeat;
      background-size: cover;

      .settings {
        position: absolute;
        right: 50px;
        top: 0px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.7);
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &:hover {
          color: #ffffff;

          .settingsIcons {
            .settingsIcon {
              path {
                fill: #ffffff;
              }
            }
          }
        }

        .settingsIcons {
          position: relative;

          .attention {
            position: absolute;
            top: -10px;
            right: -10px;
          }

          .settingsIcon {
            width: 26px;
            height: 26px;

            path {
              fill: rgba(255, 255, 255, 0.7);
            }
          }
        }
      }

      .projectInfo {
        position: relative;
        bottom: 30px;

        .toggleContainer {
          display: flex;
          align-items: center;
          color: #ffffff;
          margin-top: 5px;
          gap: 10px;
        }

        .backLink {
          color: rgba(255, 255, 255, 0.5);
          margin-bottom: 20px;
        }

        .editable {
          margin-bottom: 15px;

          div,
          input {
            font-size: 24px;
            line-height: 20px;
          }

          div {
            color: #ffffff;
          }
        }

        .tagsInfo {
          display: flex;
          align-items: center;
          gap: 10px;
        }

        .info {
          color: rgba(255, 255, 255, 0.5);
          display: flex;
          align-items: center;
          gap: 5px;
          margin: 5px 0;

          .infoName {
            color: #ffffff;
          }
        }

        .icon {
          width: 16px;
          height: 16px;

          path {
            fill: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .banner {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(40, 40, 40, 0.4);
        backdrop-filter: blur(60px);
        z-index: 1;
      }

      .bannerInfo {
        position: relative;
        top: 30px;
        display: flex;
        align-items: center;
        gap: 30px;
        width: 100%;
        max-width: 1180px;
        padding: 0 30px;
        margin: 0 auto;
        z-index: 1;

        .image {
          flex: 1 1;
          background: #f0f0f0;
          display: flex;
          align-items: center;
          justify-content: center;
          max-width: 220px;
          height: 220px;

          .previewImage {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
            background: white;
          }
        }

        .cover {
          height: 100%;
          background-color: #e6e0f4;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 220px;
          height: 220px;

          .mailSequenceIcon {
            width: 46px;
            height: 46px;

            path {
              fill: #8573bf;
            }
          }
        }
      }
    }

    .pageContent {
      max-width: 1180px;
      padding: 0 30px;
      margin: 0 auto;

      .stats {
        .statsTitle {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 18px;
          font-weight: 700;

          .statsIcon {
            width: 20px;
            height: 20px;
          }
        }

        .statsContainer {
          display: flex;
          width: 100%;
          height: 100px;
          margin-top: 20px;
          border-radius: 4px;
          overflow: hidden;
          box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);

          .divider {
            height: 100%;
            width: 2px;
            background-color: #e8e8e8;
          }

          .statsItem {
            flex: 1;
            background-color: #fafafa;
            padding: 20px 30px;

            .statsTitle {
              font-size: 24px;
              font-weight: 500;
              display: flex;
              align-items: center;
              gap: 10px;

              .statIcon {
                width: 32px;
                height: 32px;

                path {
                  fill: #000000;
                }
              }
            }

            .statDescription {
              margin-top: 10px;
              color: #a0a0a0;
            }
          }
        }
      }

      .sequenceSection {
        margin-top: 20px;
        display: flex;
        gap: 20px;

        .left {
          flex: 1;

          .sequenceHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

        .sequenceTitle {
          display: flex;
          align-items: center;
          gap: 10px;
          font-size: 18px;
          font-weight: 500;

          .daysCount {
            font-size: 14px;
            color: #5a5a5a;
            font-weight: 400;
          }
        }

        .sequenceContent {
          margin-top: 20px;
          display: flex;
          gap: 20px;

          .sequenceItems {
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 1;
          }
        }
      }
    }
  }
}
