.container {
  margin-top: 30px;

  .title {
    font-size: 18px;
    font-weight: 500;
    color: #000000;
  }

  .faviconUpload {
    margin-top: 20px;

    .faviconUploadTitle {
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      column-gap: 10px;

      .faviconPlaceholder {
        width: 16px;
        height: 16px;
        background-color: #d0d0d0;
      }

      img {
        width: 16px;
        height: 16px;
        object-fit: contain;
      }
    }

    .uploadButtonWrapper {
      display: flex;
      align-items: center;
      column-gap: 16px;

      .infoText {
        max-width: 200px;
        color: #a0a0a0;
        font-size: 12px;
        font-weight: 200;
      }
    }
  }

  .helpContainer {
    padding: 20px 0;

    .helpTitle {
      margin-bottom: 5px;
    }

    .helpSubtitle {
      font-size: 12px;
      color: #7d7c7c;
      margin-bottom: 8px;
    }

    .radioButtons {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .helpInput {
      margin-top: 15px;
      max-width: 460px;
      height: 50px;
    }
  }

  .dropdownContainer {
    margin-top: 30px;
    max-width: 460px;

    .dropdownTitle {
      color: #000000;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    .dropdown {
      span {
        span:first-of-type {
          width: 100%;
        }
      }

      .optionLabel {
        width: 90%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .colors {
          display: flex;
          .color {
            width: 20px;
            height: 20px;

            &:last-of-type {
              border-radius: 0 4px 4px 0;
            }

            &:first-of-type {
              border-radius: 4px 0 0 4px;
            }
          }
        }
      }
    }
  }

  .advancedScheme {
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--accent-accent);
    cursor: pointer;
    font-size: 14px;
    margin-top: 20px;

    .arrow {
      transition: all 0.2s;
      rect {
        fill: var(--accent-accent);
      }

      &.show {
        transform: rotate(180deg);
      }
    }
  }

  .schemeContainer {
    display: flex;
    gap: 20px;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 30px;
    height: 0;
    transition: all 0.2s;
    padding: 0;
    overflow: hidden;

    &.show {
      height: initial;
      padding: 20px;
      overflow: initial;
    }

    .schemeItem {
      flex: 1;
      min-width: 30%;

      .schemeTitle {
        font-size: 14px;
      }

      .schemeSubtitle {
        font-size: 12px;
        color: #7d7c7c;
      }

      .schemeItems {
        display: flex;
        flex-direction: column;
        gap: 2px;
        margin-top: 15px;

        .schemeItem {
          background-color: #fafafa;
          border-radius: 4px;
          padding: 6px 15px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          &:hover {
            background-color: #ffffff;
          }

          .itemTextContainer {
            max-width: 55%;

            .schemeItemSubtitle,
            .schemeTitle {
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            .schemeItemSubtitle {
              font-size: 10px;
              color: #7d7c7c;
            }
          }

          .colorContainer {
            position: relative;
            display: flex;
            align-items: center;
            gap: 20px;
            text-transform: uppercase;

            .colorPicker {
              padding: 3px;
              border: 1px solid #d0d0d0;
              border-radius: 4px;

              .picker {
                width: 20px;
                height: 20px;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 1600px) {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 260px 260px;
      gap: 20px;

      .alerts {
        grid-column: 3 / 5;
        grid-row: 2 / 3;
      }

      .sideBar {
        grid-column: 1 / 3;
        grid-row: 1 / 2;
      }

      .accent {
        grid-column: 3 / 5;
        grid-row: 1;
      }
    }
  }
}
