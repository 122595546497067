.modal {
  background-color: #fff;
  padding: 20px;

  h1,
  h2,
  h3,
  h4 {
    text-align: center;
  }

  .buttonBlock {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.copyCraftIcon {
  path {
    fill: #71717a;
  }
}

.emptyTopicContent {
  padding-top: 30px;
  text-align: center;

  .emptyTextTitle,
  .emptyTextButton {
    text-transform: capitalize;
  }
}

.chatContent {
  background-color: #e8e8e8;

  .headerContainer {
    position: relative;

    .backLink {
      position: absolute;
      top: 20px;
      left: 50px;
    }
  }

  .chatSection {
    height: calc(100vh - 130px);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }
  }

  .header {
    text-align: center;
    padding-top: 20px;
  }

  .inputSection {
    border-top: 1px #b0b0b0 solid;
    position: fixed;
    height: 100px;
    width: calc(100vw - 300px);
    top: calc(100vh - 130px);
  }

  .inputContent {
    width: 100%;
    max-width: 660px;
    margin: 0 auto;

    select {
      display: block;
      margin: 5px 0;
      background-color: #e8e8e8;
      border: none;
    }

    .inputBlock {
      position: relative;

      > textarea {
        width: 100%;
        height: 80px;
        border-radius: 15px;
        resize: none;
        padding: 6px 50px 0 12px;
        border-color: #a0a0a0;
        outline: none;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          display: none;
        }
      }

      .submitButton {
        position: absolute;
        bottom: 10px;
        right: 5px;
        background-color: #000;
        border-radius: 50%;
        border: none;
        width: 30px;
        height: 30px;
        cursor: pointer;

        > img {
          padding-bottom: 3px;
        }
      }

      .clearButton {
        position: absolute;
        bottom: 30px;
        right: -100px;
        background-color: #000;
        border-radius: 20%;
        border: none;
        width: 50px;
        height: 30px;
        color: #fff;
        cursor: pointer;
      }

      .optionsButton {
        position: absolute;
        bottom: 30px;
        right: -190px;
        background-color: #000;
        border-radius: 20%;
        border: none;
        width: 70px;
        height: 30px;
        color: #fff;
        cursor: pointer;
      }

      .options {
        padding: 10px;
        position: absolute;
        bottom: 100px;
        right: -200px;
        width: 200px;
        height: 200px;
        color: #000;
        background-color: #fff;
        z-index: 2;

        > h5 {
          margin: 10px 0 0 0;
        }
      }
    }
  }
}

.chatCardContent {
  width: 650px;
  padding-bottom: 20px;
  margin: 0 auto;

  .typeContent {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 10px 0;

    > span {
      display: inline-block;
      background-color: #d7ecdf;
      width: max-content;
      padding: 10px 20px;
      border-radius: 10px;
    }
  }

  .requestContent {
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 10px 0;

    > span {
      display: inline-block;
      background-color: #fff;
      width: max-content;
      max-width: 90%;
      padding: 10px 20px;
      border-radius: 10px;
    }
  }

  .responseContent {
    margin: 10px 0;

    > span {
      display: inline-block;
      background-color: #d7d9ec;
      padding: 20px 20px;
      border-radius: 10px;
      width: 100%;

      > br {
        display: block;
        content: ''; /* clears default height */
        margin-top: 15px;
      }
    }
  }

  .likesBlock {
    position: relative;

    > span {
      display: inline-block;
      margin-right: 20px;
      cursor: pointer;
    }

    .deleteIcon {
      position: absolute;
      top: -8px;
      right: -16px;
    }
  }

  .buttonSmall {
    background-color: #d3d3d3;
    padding: 2px 8px;
    border-radius: 10px;
    &:hover {
      background-color: #dfdfdf;
    }
  }
}

.subMenu {
  position: fixed;
  width: 200px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  z-index: 2;
  box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.08);
}

.subMenuItem {
  height: 50px;
  cursor: pointer;
  line-height: 3;
  padding-left: 20px;
  > img {
    margin-right: 15px;
  }

  &:hover {
    background-color: #f0f0f0;
  }
}

.subMenuWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.modalInput {
  border-top: none;
  border-right: none;
  border-left: none;
  width: 100%;
  outline: none;
}

.statusBlock {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    text-align: center;
  }
}

.errorText {
  position: absolute;
  left: 0;
  bottom: -12px;
  font-size: 12px;
  color: red;
}

.errorTextArea {
  border-color: red !important;
}

.dropdown {
  width: 155px;

  > span {
    svg {
      fill: none !important;
    }
  }

  @media screen and (max-width: 640px) {
    width: 100%;
  }
}
