.container {
  display: flex;
  flex-direction: column;
  padding: 120px 0 60px;

  .circleContent {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;

    .stepLabel {
      line-height: 0;
    }

    .circle {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #a0a0a0;
    }

    .users {
      position: absolute;
      top: -40px;
      left: 8px;
      background-color: #fafafa;
      color: #7d7c7c;
      border-radius: 0 4px 4px 0;
      display: flex;
      gap: 5px;
      align-items: center;
      min-width: 40px;
      justify-content: center;

      .userIcon {
        width: 12px;
        height: 12px;

        path {
          fill: #7d7c7c;
        }
      }
    }
  }

  .line {
    flex: 1;
    width: 1px;
    background-color: #a0a0a0;
    min-height: 120px;
    position: relative;
    left: 6.5px;
  }
}
