@import '../constants.scss';

.paginationContainer {
  max-width: 350px;
  margin: 20px auto;
  display: flex;
  column-gap: 8px;
  justify-content: center;

  .pageNumber,
  .dots,
  .pageButton {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px;
    height: 40px;
    border-radius: 4px;
    border: 1px solid;
    border-color: transparent;
    cursor: pointer;

    .arrow {
      fill: #a0a0a0;
    }
  }

  .dots {
    cursor: default;
    color: $grey-200;
  }

  .pageNumber {
    &.currentPage {
      background-color: $accent-500;
      color: $white;
    }

    &:hover {
      background-color: $white;
      color: $black;
    }
  }

  .pageButton {
    border-color: #a0a0a0;

    &:hover {
      border-color: black;

      .arrow {
        fill: #000000;
      }
    }

    &.disableButton {
      cursor: default;
      border-color: #d0d0d0 !important;
      .arrow {
        fill: #d0d0d0 !important;
      }
    }
  }
}
