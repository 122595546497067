.container {
  position: absolute;
  width: 100dvw;
  height: 100dvh;
  z-index: 1000;
  top: 0;
  left: 0;

  .overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 1;
  }

  .coverContainer {
    height: 100%;
    margin: 0 auto;
    width: 100%;
    max-width: 1120px;
    z-index: 2;
    position: relative;

    .contentCover {
      position: absolute;
      top: 150px;
      right: 30px;
      z-index: 4;
      width: 315px;
      border-radius: 4px;
      background: #fff;
      box-shadow: 0px 8px 10px 0px rgba(0, 0, 0, 0.1);

      .stripeLinkContainer {
        .subtitle {
          margin-bottom: 30px;
        }
      }

      .subtitle {
        color: #7d7c7c;
        margin-top: 5px;
        line-height: 19.6px;
      }

      .header {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        padding: 20px;

        .textContainer {
          .title {
            font-size: 18px;
            font-weight: 500;
            line-height: 1;
          }
        }

        .headerIcon {
          width: 32px;
          height: 16px;

          path {
            fill: #000000;
          }
        }
      }

      .divider {
        margin: 0;
        border-top-color: #e8e8e8;
      }

      .dropdownContainer {
        padding: 20px;

        .label {
          margin-bottom: 13px;
        }

        .dropdown {
          > span {
            height: 40px;
          }
        }

        .buttonsContainer {
          display: flex;
          align-items: center;
          gap: 15px;
          margin-top: 30px;
        }
      }
    }
  }
}
