.container {
  height: 120px;
  width: 100%;
  background-color: #fafafa;
  border-radius: 4px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
  cursor: pointer;

  &:hover {
    background-color: #ffffff;
  }

  .left {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    .banner {
      height: 100%;
      width: 120px;
      cursor: pointer;
      position: relative;

      &:hover {
        .editSequence {
          display: flex;
        }
      }

      .editSequence {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(37, 44, 104, 0.6);
        align-items: center;
        justify-content: center;
        display: none;

        .editIcon {
          width: 26px;
          height: 26px;

          path {
            fill: #ffffff;
          }
        }
      }

      .image {
        max-width: 100%;
        height: 100%;
        width: 100%;
      }

      .emptyImageContainer {
        background-color: #d1d5e4;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .emailIcon {
          width: 32px;
          height: 24px;

          path {
            fill: #9096b0;
          }
        }
      }
    }

    .titleContainer {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .title {
        div,
        input {
          font-size: 20px;
          font-weight: 500;
        }
      }

      .subtitle {
        div,
        input {
          font-size: 12px;
          color: #3e3e3e;
        }
      }
    }
  }

  .right {
    display: flex;
    gap: 50px;

    .stats {
      display: flex;
      align-items: center;
      gap: 45px;

      .stat {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;

        .statIcon {
          width: 16px;
          height: 16px;

          path {
            fill: #a0a0a0;
          }
        }
      }
    }
  }
}

.menuIcon {
  width: 16px;
  height: 16px;

  path {
    fill: #a0a0a0;
  }

  &.duplicateIcon {
    transform: rotate(180deg);
  }

  &.closeIcon {
    width: 12px;
  }
}
