.container {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  min-height: 100%;
  display: flex;
  flex: 1;
  user-select: none;
  position: relative;

  &:hover {
    .resizer {
      opacity: 1;
    }
  }

  &.emptyImageContainer {
    min-height: 100px;
  }

  input {
    display: none;
  }

  .imageContent {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.emptyImageContent {
      background-color: rgba(140, 147, 195, 0.2);
      border: 0.5px dashed #8c93c3;
      padding: 10px;
    }

    &.activeImageContent {
      background-color: rgba(140, 147, 195, 0.5);
    }

    .emptyImage {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 20px;
      gap: 20px;

      .emptyText {
        width: 100%;
        max-width: 230px;
        text-align: center;
        color: #8c93c3;
        font-size: 14px;
      }

      .browseButton {
        color: #8c93c3;
        border: 1px solid #8c93c3;
        background-color: transparent;
        border-radius: 4px;
        padding: 5px 16px;
        cursor: pointer;
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 14px;
      }
    }

    .image {
      height: 100%;
      width: 100%;
      display: flex;

      img {
        width: 100%;
        max-width: 100%;
      }
    }

    div {
      font-size: 20px;
    }
  }
  .generating {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    border-radius: 4px;
    animation: gradientMove 2s ease infinite;
    background: linear-gradient(40deg, #9d9d9d, #dcdcdc);
    background-position: 10% 50%;
    background-size: 200% 200%;
  }
}

@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
