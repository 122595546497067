.container {
  max-height: calc(100vh - 60px);
  overflow: hidden;

  .line {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .pageName {
      width: 130px;
      height: 25px;
    }

    .addButton {
      width: 420px;
      height: 40px;
    }

    .sort {
      width: 166px;
      height: 17px;
    }

    .title {
      width: 220px;
      height: 35px;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 15px;
  }

  .body {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding-top: 30px;

    .line {
      margin-bottom: 20px;
    }

    .item {
      width: 100%;
      height: 60px;
    }
  }
}
