.container {
  flex: 1;
  display: flex;
  flex-direction: column;

  .title {
    text-align: center;
    margin-bottom: 20px;
    line-height: 10px;
    font-weight: 400;
  }

  .downloadLink {
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
    font-size: 14px;
    font-weight: 400;

    svg {
      transform: rotate(-90deg);
    }
  }

  .browseFile {
    background-color: #e5e6f0;
    padding: 10px;
    max-width: 738px;
    margin: 0 auto;
    border-radius: 4px;
    flex: 1;
    width: 100%;

    &.activeImageContent {
      background-color: rgba(140, 147, 195, 0.5);
    }

    .draggableContent {
      border: 0.5px dashed #8c93c3;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .checkMark {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #25bb73;
        border-radius: 50%;
        margin-bottom: 22px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      .remove {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;
        margin-top: 14px;
        font-weight: 400;

        &:hover {
          .text {
            color: #4719ff;
          }

          .closeIcon {
            path {
              fill: #4719ff;
            }
          }
        }

        .closeIcon {
          width: 16px;
          height: 16px;

          path {
            fill: #8c93c3;
          }
        }
      }

      .text {
        color: #8c93c3;
        line-height: 10px;
        font-weight: 400;
      }

      .browseButton {
        color: #8c93c3;
        border: 1px solid #8c93c3;
        background-color: transparent;
        margin-top: 20px;
        border-radius: 4px;
        padding: 12px 42px;
        cursor: pointer;
        display: flex;
        gap: 8px;
        align-items: center;
        font-size: 14px;
        line-height: 10px;

        &:hover {
          color: #4719ff;
          border: 1px solid #4719ff;
        }

        svg {
          fill: currentColor;
        }
      }
    }
  }
}
