@import '../constants.scss';

.custom-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 0px;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      background-color: $green-500;
      border-color: $green-500;

      &::after {
        display: block;
      }
    }
  }

  .checkmark {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    background-color: $white;
    border: 1px solid $grey-600;
    border-radius: 5px;
    transition: all 0.3s;

    &::after {
      content: '';
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 6px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }

  .minus {
    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 2px;
      background-color: white;
      border: none;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover input ~ .checkmark {
    border-color: $grey-500;
  }

  input:checked ~ .checkmark {
    background-color: $green-500;
    border-color: $green-500;

    &:hover {
      background-color: $green-400;
      border-color: $green-400;
    }
  }
}
