.popup {
  width: 100%;
  max-width: 800px;
  background-color: #fafafa !important;

  .container {
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    height: calc(0.9 * 100dvh);
    max-height: 777px;

    .buttonsContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    .divider {
      height: 1px;
      background-color: #d0d0d0;
      margin: 30px 0;

      &.secondDivider {
        margin: 30px 0 20px;
      }
    }

    .shadow {
      position: absolute;
      top: 163px;
      left: 0;
      width: 100%;
      height: 20px;
      background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0));
      z-index: 1;

      &.bottom {
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.05));
        top: unset;
        bottom: 90px;
      }
    }

    .title {
      font-size: 24px;
      font-weight: 500;
      text-align: center;
      line-height: 17px;
      margin-bottom: 14px;
    }

    .subtitle {
      text-align: center;
      color: #a0a0a0;
      font-weight: 300;
      line-height: 10px;
    }

    .steps {
      display: flex;
      gap: 13px;
      align-items: center;
      justify-content: center;
      margin-top: 23px;

      .stepItem {
        display: flex;
        gap: 10px;
        align-items: center;
        font-weight: 400;

        .stepNumber {
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #d0d0d0;
          border-radius: 50%;

          &.passedStep {
            background-color: rgba(37, 187, 115, 0.2);
            border-color: rgba(37, 187, 115, 0.2);
          }
        }

        .arrow {
          width: 16px;
          height: 16px;
          transform: rotate(-90deg);

          path {
            fill: #d0d0d0;
          }
        }
      }
    }
  }
  .progressBarContainer {
    margin: auto;
    height: 200px;
    width: 50%;

    display: flex;

    .bar {
      align-self: center;
      width: 100%;
      height: 10px;
    }
    .label {
      padding: 10px;
      text-align: center;
      color: #a0a0a0;
    }
  }
}
